import React from 'react'
import { BaseLayout, DefaultNavbar } from '@components/common'
import { PageComponent } from 'types/app'
import { Typography } from '@mui/material'
import { withLayout, withProps } from '@utils/hoc'

const SuperYa404: PageComponent = () => {
  return (
    <Typography variant="h3" className="container mx-auto flex flex-col justify-center text-center">
      404 | La page demandée n'existe pas
    </Typography>
  )
}

const Layout = withProps(BaseLayout, { navbar: <DefaultNavbar /> })

export default withLayout(SuperYa404, Layout)
